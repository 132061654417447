import { Dispatch } from "react"
import * as Ws from './Ws'

const showBriefError = (dispatch: Dispatch<Action>, text: string) => {
  dispatch({type: 'error', value: text})
  setTimeout(() => {
    dispatch({type: 'error', value: ''})
  }, 5000)
}

function login(dispatch: Dispatch<Action>, userId: string, pwd: string) {
  dispatch({ type: 'busy', value: true })
  return Ws.login(userId, pwd)
    .then((result: any) => {
      if (result.error)
        throw new Error(result.error.message)
      else
        dispatch({
          type: 'session',
          value: {
            sessionId: result.result.sessionId,
            isAdmin: result.result.isAdmin,
          }
        })
    }).catch((error: Error) => {
      showBriefError(dispatch, error.message)
    }).finally(() => {
      dispatch({ type: 'busy', value: false })
    })
}

function logout(dispatch: Dispatch<Action>) {
  dispatch({
    type: 'session',
    value: {
      sessionId: 0,
      isAdmin: false,
    }
  })
}

function makeCatchPrivate(state: State, dispatch: Dispatch<Action>, params: any) {
  return Ws.makeCatchPrivate(
    state.sessionId,
    params.id,
  ).then((result: any) => {
    if (result.error)
      throw new Error(result.error)
  }).catch((error: Error) => {
    showBriefError(dispatch, error.message)
  })
}

function makeCatchPublic(state: State, dispatch: Dispatch<Action>, params: any) {
  return Ws.makeCatchPublic(
    state.sessionId,
    params.id,
  ).then((result: any) => {
    if (result.error)
      throw new Error(result.error)
  }).catch((error: Error) => {
    showBriefError(dispatch, error.message)
  })
}

function updateCatch(state: State, dispatch: Dispatch<Action>, params: any) {
  return Ws.updateCatch({
    ...params,
    sessionId: state.sessionId,
  }).then((result: any) => {
    if (result.error)
      throw new Error(result.error.message)
    return result
  }).catch((error: Error) => {
    showBriefError(dispatch, error.message)
  })
}

function loadCatches(state: State, dispatch: Dispatch<Action>, params?: any) {
  return Ws.getCatchList(
    params?.offset || 0,
    params?.count || 10,
    params?.sortBy || state.filters.qsb,
    params?.sortOrder || state.filters.qso,
    params?.filter || state.filters.qf,
    params?.queryType || state.filters.qt,
  ).then((result: any) => {
    dispatch({
      type: 'catches',
      value: {
        offset: params?.offset || 0,
        remain: result.remain,
        nextOffset: result.nextOffset,
        catches: result.catches
      }
    })
  })
}

function checkSession(state: State, dispatch: Dispatch<Action>) {
  if (!!state.sessionId) {
    dispatch({ type: 'busy', value: true })
    Ws.checkSession(state.sessionId)
      .then((result: any) => {
        dispatch({
          type: 'session',
          value: {
            sessionId: !result.error ? state.sessionId : 0,
            isAdmin: !result.error ? result.isAdmin : 0,
          }
        })
      }).finally(() => {
        dispatch({ type: 'busy', value: false })
      })
  }
}

export {
  login,
  makeCatchPrivate,
  makeCatchPublic,
  checkSession,
  loadCatches,
  logout,
  updateCatch,
}