import { Dispatch, ReactNode, createContext, useContext, useReducer } from "react";
import reducer from "./reducer";
import ClientStorage from "../ClientStorage";

const cs = new ClientStorage()

const initialState: State = {
  error: '',
  isAdmin: false,
  busy: false,
  sessionId: cs.getLocal('/sid', 0),
  catchListInfo: {
    remain: null,
    offset: 0,
    nextOffset: null,
    catches: null,
  },
  filters: {
    qf: cs.getLocal('/qf', ''),
    qsb: cs.getLocal('/qsb', 'caught'),
    qso: cs.getLocal('/qso', 'd'),
    qt: cs.getLocal('/qt', 3),
  },
}

const GlobalContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => { },
});

export const GlobalContextProvider = ({children}: {children: ReactNode}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalContext: ContextHook = () => {
  const { state, dispatch } = useContext(GlobalContext)
  return { state, dispatch }
}