import { Box, Button, TextField } from "@mui/material"
import { useState } from "react"
import { login, logout } from "../ws-helpers"
import { useGlobalContext } from "../context"

export default function SignInPanel(props: any) {
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const { state, dispatch } = useGlobalContext()

  const signInClicked = () => {
    if (!!state.sessionId)
      logout(dispatch)
    else
      login(dispatch, userId, password)
  }

  return (
    <>
      {!state.sessionId &&
      <Box
        sx={{mt: 2, mx: 1}}
      >
        <TextField
          value={userId}
          fullWidth
          label="user id"
          sx={{mb: 2}}
          onChange={(e: any) => setUserId(e.target.value)}
        ></TextField>
        <TextField
          value={password}
          fullWidth
          label="password"
          type="password"
          sx={{mb: 2}}
          onChange={(e: any) => setPassword(e.target.value)}
        ></TextField>
      </Box>
      }
      <Button
        variant="contained"
        fullWidth
        onClick={ signInClicked }
        sx={{mb: 1, px: 1}}
      >{ state.sessionId ? 'Sign out' : 'Sign in' }</Button>
    </>
  )
}