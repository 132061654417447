import { useState } from 'react';
import CatchPanel from '../components/CatchPanel'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CatchOptsDlg from './CatchOptsDlg';
import { useNavigate } from 'react-router-dom';
import { FLAGS_EDITORS_CHOICE, ORDER_ASC, ORDER_BY_CAUGHT, ORDER_BY_CREATED, 
  ORDER_BY_WEIGHT, ORDER_DESC, QUERY_EDITORS_CHOICE, QUERY_PRIVATE, 
  QUERY_PUBLIC } from '../Ws';
import SignInPanel from '../components/SignInPanel';
import { useGlobalContext } from '../context';
import { loadCatches, makeCatchPrivate, makeCatchPublic, updateCatch } from '../ws-helpers';

function CatchList(props: any) {
  const { state, dispatch } = useGlobalContext()
  const [criteriaPanel, setCriteriaPanel] = useState(false)
  const [catch_, setCatch_] = useState(null) as Array<any>
  const navigate = useNavigate()

  const catchClicked = (catch_: any) => {
    if (!!state.sessionId)
      setCatch_(catch_)
  }

  const onChoice = (choice: string) => {
    const id = catch_.id
    const flags = catch_.flags
    setCatch_(null)
    if (choice === 'edit')
      navigate(`/edit/${id}`)
    else if (choice === 'make-private')
      makeCatchPrivate(state, dispatch, {id}).then(() => {
        loadCatches(state, dispatch)
      })
    else if (choice === 'make-public')
      makeCatchPublic(state, dispatch, {id}).then(() => {
        loadCatches(state, dispatch)
      })
    else if (choice === 'toggle-editors-choice')
      updateCatch(state, dispatch, {
        id,
        flags: flags & FLAGS_EDITORS_CHOICE
          ? `-${FLAGS_EDITORS_CHOICE}`
          : `+${FLAGS_EDITORS_CHOICE}`
      }).then(() => {
        loadCatches(state, dispatch)
      })
  }

  return (
    <Box className="CatchList">
      {!!catch_ &&
        <CatchOptsDlg
          fullWidth
          catch={catch_}
          handleClose={() => setCatch_(null)}
          onChoice={(choice: string) => onChoice(choice)}
        />
      }
      <SignInPanel />
      <Button
        variant="contained"
        fullWidth
        onClick={ e => { setCriteriaPanel(!criteriaPanel) } }
        sx={{mb: 1, px: 1}}
      >Search</Button>
      {criteriaPanel &&
      <Box
        sx={{mx: 1}}
      >
        <FormControl
          fullWidth
          sx={{mt: 2}}
        >
          <InputLabel id="demo-simple-select-label">Show catches of type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.filters.qt}
            label="Show catches of type"
            onChange={(e: any) => {
              dispatch({
                type: 'filters',
                value: {qt: e.target.value}
              })
            }}
          >
            <MenuItem value={3}>All</MenuItem>
            <MenuItem value={QUERY_PRIVATE}>Private</MenuItem>
            <MenuItem value={QUERY_PUBLIC}>Public</MenuItem>
            <MenuItem value={QUERY_EDITORS_CHOICE}>Editors Choice</MenuItem>
          </Select>
        </FormControl>        
        <FormControl
          fullWidth
          sx={{mt: 2}}
        >
          <InputLabel id="demo-simple-select-label2">Sort By</InputLabel>
          <Select
            labelId="demo-simple-select-label2"
            id="demo-simple-select"
            value={state.filters.qsb}
            label="Sort By"
            onChange={(e: any) => {
              dispatch({
                type: 'filters',
                value: {qsb: e.target.value}
              })
            }}
          >
            <MenuItem value={ORDER_BY_CAUGHT}>Caught Date</MenuItem>
            <MenuItem value={ORDER_BY_CREATED}>Created Date</MenuItem>
            <MenuItem value={ORDER_BY_WEIGHT}>Weight</MenuItem>
          </Select>
        </FormControl>        
        <FormControl
          fullWidth
          sx={{mt: 2}}
        >
          <InputLabel id="demo-simple-select-label3">Sort Order</InputLabel>
          <Select
            labelId="demo-simple-select-label3"
            id="demo-simple-select"
            value={state.filters.qso}
            label="Sort Order"
            onChange={(e:any) => {
              dispatch({
                type: 'filters',
                value: {qso: e.target.value}
              })
            }}            
          >
            <MenuItem value={ORDER_DESC}>Descending (Z-A, 10-1)</MenuItem>
            <MenuItem value={ORDER_ASC}>Ascending (A-Z, 1-10)</MenuItem>
          </Select>
        </FormControl>    
        <TextField
            value={state.filters.qf}
            fullWidth
            label="Filter"
            sx={{mt: 2}}
            onChange={(e: any) => {
              dispatch({
                type: 'filters',
                value: {qf: e.target.value}
              })
            }}            
        ></TextField>
      </Box>
      }
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 1,
          my: 1,
        }}
      >
        <Button
          disabled={!state.catchListInfo.offset}
          fullWidth
          variant="outlined"
          onClick={() => {
            setCriteriaPanel(false)
            dispatch({type: 'busy', value: true})
            loadCatches(state, dispatch, {offset: state.catchListInfo.offset - 10})
              .finally(() => dispatch({type: 'busy', value: false}))
          }}
          sx={{width: '32%'}}
        >{'< < <'}</Button>
        <Button
          variant="outlined"
          onClick={() => {
            setCriteriaPanel(false)
            dispatch({type: 'busy', value: true})
            loadCatches(state, dispatch, {offset: 0})
              .finally(() => dispatch({type: 'busy', value: false}))
            
          }}
          fullWidth
          sx={{width: '32%'}}
        >{criteriaPanel ? 'Apply' : 'Refresh'}</Button>
        <Button
          variant="outlined"
          disabled={!state.catchListInfo.remain}
          fullWidth
          onClick={() => {
            setCriteriaPanel(false)
            dispatch({type: 'busy', value: true})
            loadCatches(state, dispatch, {offset: state.catchListInfo.nextOffset})
              .finally(() => dispatch({type: 'busy', value: false}))
          }}
          sx={{width: '32%'}}
        >{'> > >'}</Button>
      </Box>
      {state.catchListInfo.catches?.map((c: any, i: number) => (
        <CatchPanel
          catch={c}
          key={i}
          onCatchClicked={(c: any) => catchClicked(c)}
        />
      ))}
    </Box>
  )
}

export default CatchList
