import searchMyDiaryCell from '../images/search-mydiary-cell.png'
import locked from '../images/locked.png'
import './CatchPanel.scss'
import { FLAGS_DO_NOT_SHARE, FLAGS_EDITORS_CHOICE } from '../Ws'
import { DateTime } from 'luxon'

function CatchPanel(props) {
  const catchImgData = `data:image/jpeg;base64,${props.catch.extra.thumb_image_data}`
  const weatherImgData = `data:image/jpeg;base64,${props.catch.extra.weather_image_data}`
  const mapImgData = `data:image/jpeg;base64,${props.catch.extra.map_image_data}`
  
  function convertDate(ts, zone) {
    const tz = !!zone || 'Europe/London'
    return DateTime.fromSeconds(ts).setZone(tz).toFormat('dd LLL yyyy')
  }

  return (
    <div
      style={{ cursor: 'pointer' }}
      className="catch-panel"
      onClick={e => props.onCatchClicked(props.catch)}
    >
      <img
        src={searchMyDiaryCell}
        alt=""
      />
      <img
        className="image"
        src={catchImgData}
        alt=""
      />
      {(props.catch.flags & FLAGS_DO_NOT_SHARE) != 0 &&
        <img
          className="locked"
          src={locked}
          alt=""
        />
      }
      <img
        className="weather"
        src={weatherImgData}
        alt=""
      />
      <img
        className="map"
        src={mapImgData}
        alt=""
      />
      <span
        className="description"
      >
        {(props.catch.flags & FLAGS_EDITORS_CHOICE) != 0 &&
        <span
        >&#9733;</span>
        }
        <span
        >{props.catch.description}&nbsp;-&nbsp;{props.catch.extra.weight_imperial}
        </span>
      </span>
      <span
        className="text location"
      >{props.catch.location}</span>
      <span
        className="text caught"
      >{convertDate(props.catch.caught, props.caught?.extra?.location_fields?.tz)}</span>
      <span
        className="text name"
      >{props.catch.user.first_name}&nbsp;{props.catch.user.last_name}</span>
    </div>
  )
}

export default CatchPanel