import React, { createContext, useReducer } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CatchEdit, loader as catchEditLoader } from './views/CatchEdit'
import CssBaseline from '@mui/material/CssBaseline'
import CatchList from './views/CatchList'
import App from './App'
import { GlobalContextProvider } from './context';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "catchlist",
        element: <CatchList />,
      },
      {
        path: "edit/:id",
        element: <CatchEdit />,
        loader: (req: any) => catchEditLoader(req),
      }
    ],
  },
], {
  basename: process.env.PUBLIC_URL || "/",  
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalContextProvider>
      <RouterProvider router={router} />
    </GlobalContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
