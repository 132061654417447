interface URL {
    url: string,
    params: any
}
function fetchDelete (url: string | URL,
    data?: any, cb?: (result: any) => void) : any
{
    return _fetchCore('delete', url, data, cb);
}
function fetchGet (url: string | URL,
    data?: any, cb?: (result: any) => void) : any
{ 
    return _fetchCore('get', url, data, cb);
}
function fetchPost (url: string | URL,
    data?: any, cb?: (result: any) => void) : any
{
    return _fetchCore('post', url, data, cb);
}
function fetchPut (url: string | URL,
    data?: any, cb?: (result: any) => void) : any
{
    return _fetchCore('put', url, data, cb);
}
/*
method = get|post|put|delete
dest = <url string> or {url: <url string>, params: {key: val, ...}}
data = {key: val, key: val, ...} or 'FormData'
cb = callback func (cb(JSON result)) or null
*/
function _fetchCore (method: string, dest: string | URL,
    data?: any, cb?: (result: any) => void, opts?: any)
{
    const opts1: any = {
        method: method, 
        mode: 'cors',
        ...opts
    }
    const params: string[] = []
    let url: string = typeof dest === 'string'
        ? dest : dest.url
    let fd: any | FormData
    if (!data) data = {}
    if (method == 'post'
        || method == 'put') {
        fd = data instanceof FormData
            ? data : new FormData()
        opts1['body'] = fd
    }
    Object.getOwnPropertyNames(data).forEach((k) => {
        let tmp = typeof data[k] == 'object'
            ? JSON.stringify(data[k]) : data[k]
        if (typeof tmp == 'boolean') {
            tmp = tmp ? 1 : 0
        }
        if (fd) {
            fd.append(k, tmp)
        } else {
            params.push(`${k}=${encodeURI(tmp)}`)
        }
    });
    if (typeof dest !== 'string') {
        Object.getOwnPropertyNames(dest.params).forEach((k) => {
            params.push(`${k}=${encodeURI(dest.params[k])}`)
        })
    }
    if (!fd && params.length) {
        url = `${url}?${params.join('&')}`
    }
    if (!cb) {
        return fetch(url, opts1)
            .then(result => result.json())
    } else {
        fetch(url, opts1)
        .then(result => result.json())
        .then(json => cb(json));
        return true
    }
}

export {
    fetchGet,
    fetchDelete,
    fetchPost,
    fetchPut,
}