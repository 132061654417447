class ClientStorage
{
    section;

    constructor (section: any = null) {
        this.section = section ?? 'gf'
        if (!window.localStorage
            || !window.sessionStorage) {
            throw new Error('localStorage or sessionStorage are required')
        }
    }

    _makeKeyPath(keyPath: string|null) {
        return keyPath && keyPath.length
            ? `${this.section}.${keyPath}`
            : this.section
    }

    setSession(keyPath = '', val: any) {
        this._core('ss', this._makeKeyPath(keyPath), val)
    }

    getSession(keyPath = '', defValue: any = null) {
        return this._core('sg', this._makeKeyPath(keyPath)) ?? defValue
    }

    delSession(keyPath = '') {
        this._core('sd', this._makeKeyPath(keyPath))
    }

    setLocal(keyPath = '', val: any) {
        this._core('ls', this._makeKeyPath(keyPath), val)
    }

    getLocal(keyPath = '', defValue: any = null) {
        return this._core('lg', this._makeKeyPath(keyPath)) ?? defValue
    }

    delLocal(keyPath = '') {
        this._core('ld', this._makeKeyPath(keyPath))
    }

    _core(op: string, keyPath: string, val: any = null) {
        let engine
        if (op.startsWith('s')) {
            engine = window.sessionStorage
        } else if (op.startsWith('l')) {
            engine = window.localStorage
        } else {
            throw new Error(`invalid engine opcode [${op}]`)            
        }
        const parts: Array<string> = keyPath.split('.')
        const baseKey: any = parts.shift()
        let base: any = engine.getItem(baseKey) ?? '{}'
        let p = true
        try { base = atob(base) } catch { p = false }
        base = JSON.parse(base)
        if (p === false) delete base['data']
        let obj: any = base
        parts.slice(0, parts.length - 1).forEach(
            (part: any) => {
                if (!obj[part]) {
                    obj[part] = {}
                }
                obj = obj[part]
            }
        )
        if (op.endsWith('g')) {
            if (!parts.length) return obj
            else return obj[parts[parts.length - 1]]
        } else if (op.endsWith('s')) {
            if (!parts.length) obj = val
            else obj[parts[parts.length - 1]] = val
        } else if (op.endsWith('d')) {
            if (!parts.length) { engine.deleteItem(baseKey); return }
            else delete obj[parts[parts.length - 1]] 
        } else {
            throw new Error(`invalid storage opcode [${op}]`)
        }
        try { engine.setItem(baseKey, btoa(JSON.stringify(base))) }
        catch { throw new Error(`clientStorage failed [${keyPath}] [${baseKey}]`)}
    }
}

export default ClientStorage