import ClientStorage from "../ClientStorage"

const cs = new ClientStorage()

const reducer: ReducerType = (state: State, action: Action) => {
  switch (action.type) {
    case 'error':
      return {...state, error: action.value}
    case 'filters':
      let filters = { ...state.filters } || {}
      for (const [k, v] of Object.entries(action.value))
        cs.setLocal(`/${k}`, v)
      return { ...state, filters: { ...filters, ...action.value } }
    case 'catch':
      const ix = state.catchListInfo.catches?.findIndex((c: any) => {
        return c.id == action.value.id
      })
      if (typeof ix != 'undefined'
        && ix >= 0
        && !!state.catchListInfo.catches)
        return {
          ...state,
          catchListInfo: {
            ...state.catchListInfo,
            catches: state.catchListInfo.catches.map((c: any) => {
              if (c.id == action.value.id) {
                return {...c, ...action.value}
              } else
                return {...c}
            })
          }
        }
      else
        return {...state}
    case 'catches':
      return { ...state, catchListInfo: { ...action.value } }
    case 'busy':
      return { ...state, busy: !!action.value ? true : false }
    case 'session':
      const sessionId = action.value.sessionId
      cs.setLocal('/sid', sessionId)
      return { ...state, ...action.value }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}

export default reducer
