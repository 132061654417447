import { useEffect, useState } from 'react';
import './App.scss';
import { Alert, Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import { checkSession, loadCatches } from './ws-helpers';
import { useGlobalContext } from './context';

function App() {
  const navigate = useNavigate()
  const { state, dispatch } = useGlobalContext()

  useEffect(() => {
    navigate('/catchlist')
    checkSession(state, dispatch)
    dispatch({type: 'busy', value: true})
    loadCatches(state, dispatch).finally(() => {
      dispatch({type: 'busy', value: false})
    })
  }, [])

  return (
    <>
      <Backdrop open={state.busy} sx={{zIndex: 99}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className="App">
        {!!state.error?.length &&
        <Alert
          sx={{my: 1}}
          severity="error"
        >{state.error}</Alert>
        }
        <Outlet />      
      </Box>
    </>
  )
}

export default App
