import { Button } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { useState } from "react"
import { FLAGS_PRIVATE } from "../Ws"

function CatchOptsDlg(props: any) {
  const [dlgOpen, setDlgOpen] = useState(true)
  
  const handleClose = () => {
    props?.handleClose()
    setDlgOpen(false)
  }

  const onChoice = (choice: string) => {
    setDlgOpen(false)
    props?.onChoice(choice)
  }

  return (
    <Dialog
      open={dlgOpen}
      onClose={handleClose}
      maxWidth={'sm'}
    >
      <DialogContent>
        <Button
            fullWidth
            variant="contained"
            onClick={() => onChoice('edit')}
        >Edit...</Button>
        {!props.catch.has_location_map &&
        <Button
          sx={{mt:1}}
          fullWidth
          variant="contained"
          onClick={() => onChoice('update-map')}
        >Update map...</Button>
        }
        {(!props.catch.has_weather
          || !props.has_weather_history) &&
        <Button
          sx={{mt:1}}
          fullWidth
          variant="contained"
          onClick={() => onChoice('update-weather')}
        >Update weather...</Button>
        }
        {!!(props.catch.flags & FLAGS_PRIVATE) &&
        <Button
          sx={{mt:1}}
          fullWidth
          variant="contained"
          onClick={() => onChoice('make-public')}
        >Make public</Button>
        }
        {!(props.catch.flags & FLAGS_PRIVATE) &&
        <>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            onClick={() => onChoice('make-private')}
          >Make private</Button>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            onClick={() => onChoice('toggle-editors-choice')}
          >Toggle editors choice...</Button>
          {false &&
          <><Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            onClick={() => onChoice('twitter')}
          >Tweet...</Button>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            onClick={() => onChoice('facebook')}
          >Facebook...</Button></>
          }
        </>
        }
      </DialogContent>
    </Dialog>
  )
}

export default CatchOptsDlg
