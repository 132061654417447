import { Button, Chip, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import { useFormik } from "formik"
import { useCallback, useRef, useState } from "react"
import { useLoaderData, useNavigate } from "react-router-dom"
import { getCatch } from "../Ws"
import * as Yup from 'yup'
import { DateTime } from "luxon"
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import { useGlobalContext } from "../context"
import { updateCatch } from "../ws-helpers"

function CatchEdit(props: any) {
  const catch_: any = useLoaderData()
  const [lat, setLat] = useState(catch_.lat)
  const [lng, setLng] = useState(catch_.lng)
  const [image, setImage] = useState(null)
  const [imageName, setImageName] = useState(null)
  const navigate = useNavigate()
  const [map, setMap] = useState(null)
  const fileRef = useRef(null) as any
  const { state, dispatch } = useGlobalContext()

  const onFileChange = () => {
    const reader = new FileReader()
    reader.onload = () => {
      setImage(btoa(reader.result as string) as any)
      setImageName(fileRef.current.files[0].name)
      fileRef.current.value = null
    }
    reader.readAsBinaryString(fileRef.current.files[0]);
  }

  const onLoad = useCallback((map: any) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback((map: any) => {
    setMap(null)
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string
  })

  const formik = useFormik({
    initialValues: {
      description: catch_.description,
      bait: catch_.bait,
      notes: catch_.notes,
      venue: catch_.water_name,
      peg: catch_.peg,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required('Required')
        .max(100, 'Must be 100 characters or less'),
      bait: Yup.string()
        .max(255, 'Must be 255 characters or less'),
      peg: Yup.string()
        .max(10, 'Must be 10 characters or less'),
      notes: Yup.string()
        .max(1024, 'Must be 1024 characters or less'),
      venue: Yup.string()
        .max(100, 'Must be 100 characters or less'),
    }),
    onSubmit: values => {
      updateCatch(state, dispatch, {
        id: catch_.id,
        ...values,
        lat,
        lng,
        image,
      }).then((res: any) => {
        if (!!res) {
          dispatch({
            type: 'catch',
            value: {
              id: catch_.id,
              ...values,
              lat,
              lng,
            }
          })
          navigate('/catchlist')
        }
      })
    },
  })

  function convertDateTime(ts: number, zone?: string) {
    return DateTime.fromSeconds(ts).setZone(zone).toFormat('dd LLL yyyy HH:mm')
  }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <img
          src={'data:image/jpeg;base64,' + catch_.extra.image_data}
          alt="catch"
          width="100%"
        />
        {!!imageName &&
          <Chip
            sx={{ mt: 1, width: "100%" }}
            variant="filled"
            label={imageName}
            color="primary"
            onDelete={() => {
              setImage(null)
              setImageName(null)
            }}
          />
        }
        <input
          style={{ display: 'none' }}
          ref={fileRef}
          name="file"
          type="file"
          accept="image/png,image/jpeg"
          onChange={onFileChange}
        />
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          onClick={() => fileRef.current.click()}
        >Select new image...</Button>
        {isLoaded &&
          <Box
            sx={{ mt: 2 }}
          >
            <GoogleMap
              options={{
                disableDefaultUI: true,
                gestureHandling: "none",
                clickableIcons: false,
              }}
              mapContainerStyle={{
                width: '320px',
                height: '320px'
              }}
              center={{
                lat: catch_.lat,
                lng: catch_.lng
              }}
              zoom={13}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {!!map &&
                <MarkerF
                  draggable
                  position={{
                    lat: lat,
                    lng: lng,
                  }}
                  onDragEnd={(e: any) => {
                    setLat(e.latLng.lat())
                    setLng(e.latLng.lng())
                  }}
                />
              }
            </GoogleMap>
          </Box>
        }
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="description"
          id="description"
          {...formik.getFieldProps('description')}
          error={!!formik.errors?.description}
          helperText={formik.touched.description
            && formik.errors?.description as string}
        ></TextField>
        <TextField
          disabled
          fullWidth
          sx={{ mt: 2 }}
          label="Caught on"
          value={convertDateTime(catch_.caught)}
        ></TextField>
        <TextField
          fullWidth
          disabled
          sx={{ mt: 2 }}
          label="Weight (I)"
          value={catch_.extra.weight_imperial}
        ></TextField>
        <TextField
          fullWidth
          disabled
          sx={{ mt: 2 }}
          label="Weight (M)"
          value={catch_.extra.weight_metric}
        ></TextField>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Bait"
          id="bait"
          {...formik.getFieldProps('bait')}
          error={!!formik.errors?.bait}
          helperText={formik.touched.bait
            && formik.errors?.bait as string}
        ></TextField>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Peg"
          id="peg"
          {...formik.getFieldProps('peg')}
          error={!!formik.errors?.peg}
          helperText={formik.touched.peg
            && formik.errors?.peg as string}
        ></TextField>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Notes"
          id="notes"
          {...formik.getFieldProps('notes')}
          error={!!formik.errors?.notes}
          helperText={formik.touched.notes
            && formik.errors?.notes as string}
        ></TextField>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          label="Venue"
          id="bait"
          {...formik.getFieldProps('venue')}
          error={!!formik.errors?.venue}
          helperText={formik.touched.venue
            && formik.errors?.venue as string}
        ></TextField>
        <Box
          sx={{ mt: 2, display: 'flex' }}
        >
          <Button
            fullWidth
            variant="text"
            sx={{ mx: 1 }}
            onClick={() => navigate('/catchlist')}
          >Cancel</Button>
          <Button
            type="submit"
            fullWidth
            variant="text"
            sx={{ mx: 1 }}
          >Save</Button>
        </Box>
      </form>
    </Box>
  )
}

function loader(req: any) {
  return new Promise((res, rej) => {
    getCatch(req.params.id)
      .then((result: any) => {
        res(result)
      }).catch((err: Error) => {
        rej(err)
      })
  })
}

export {
  CatchEdit,
  loader
}
