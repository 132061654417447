import { fetchPost } from "./fetchHelpers"

function jsonRpc(jsonMethod, params, id = 1) {
    const req = {
        'jsonrpc': '2.0',
        'method': jsonMethod,
        'id': id,
        'params': params,
    }
    return fetchPost(process.env.REACT_APP_WS_URL, {
        'q': encodeURI(JSON.stringify(req))
    })
}

function checkSession(sessionId) {
    return jsonRpc('checkSession', {
        sessionId: sessionId,
    }, 1).then((result) => {
        if (result.error)
            throw new Error(result.error.message)
        return result
    })
}

function updateCatch(params) {
    return jsonRpc('updateCatch', {
        sessionId: params.sessionId || 0,
        id: params.id || 0,
        description: params.description || '',
        weight: params.weight || '',
        bait: params.bait || '',
        notes: params.notes || '',
        lat: params.lat || '',
        lng: params.lng || '',
        location: params.location || '',
        peg: params.peg || '',
        waterName: params.venue || '',
        flags: typeof params.flags != 'undefined' ? params.flags : '',
        image: params.image || '',
    }, 1).then((result) => {
        return result
    })
}

function makeCatchPrivate(sessionId, catchId) {
    return jsonRpc('makeCatchPrivate', {
        sessionId, catchId,
    }, 1).then((result) => {
        return result
    })
}

function makeCatchPublic(sessionId, catchId) {
    return jsonRpc('makeCatchPublic', {
        sessionId, catchId,
    }, 1).then((result) => {
        return result
    })
}

function getCatch(id)
{
    return jsonRpc('getCatch', {
        sessionId: 0,
        width: 0,
        catchId: id,
    }, 1).then((result) => {
        return result.result.catch
    })
}

function getCatchList(offset = 0, count = 10, orderBy = 'caught',
    orderDir = 'd', textFilter = '', typeFilter = 3)
{
    return jsonRpc('getCatchList', {
        userId: 0,
        text: textFilter,
        lat: '-',
        lng: '-',
        offset: offset,
        count: count,
        flags: typeFilter,
        orderBy: orderBy,
        orderDir: orderDir,
        sessionId: '',
    }, 1).then((result) => {
        return {
            catches: result.result.catches,
            remain: result.result.remain,
            nextOffset: result.result.nextOffset,
        }
    })
}

function login(userId, pwd)
{
    return jsonRpc('login', {
        userId, pwd
    }, 1).then((result) => {
        return result
    })
}

const FLAGS_PRIVATE = 1
const FLAGS_EDITORS_CHOICE = 4
const FLAGS_DO_NOT_SHARE = 8

const QUERY_PRIVATE = 1
const QUERY_PUBLIC = 2
const QUERY_EDITORS_CHOICE = 4
const QUERY_GEOSPATIAL = 8
const QUERY_IGNORE_IMAGES = 16

const ORDER_BY_CAUGHT = 'caught'
const ORDER_BY_CREATED = 'created'
const ORDER_BY_WEIGHT = 'weight'

const ORDER_ASC = 'a'
const ORDER_DESC = 'd'

export {
    getCatchList,
    getCatch,
    makeCatchPrivate,
    makeCatchPublic,
    updateCatch,
    login,
    checkSession,
    FLAGS_DO_NOT_SHARE,
    FLAGS_EDITORS_CHOICE,
    FLAGS_PRIVATE,
    QUERY_PRIVATE,
    QUERY_PUBLIC,    
    QUERY_EDITORS_CHOICE,
    QUERY_GEOSPATIAL,
    QUERY_IGNORE_IMAGES,
    ORDER_BY_CAUGHT,
    ORDER_BY_CREATED,
    ORDER_BY_WEIGHT,
    ORDER_ASC,
    ORDER_DESC,
}